import React, { useState } from "react";
import logo from "../images/logo.png";
import { useLocation } from "react-router-dom";

function NavBar({ handleScroll, aboutRef, priceRef }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  console.log(isHomePage);
  return (
    <div className="header">
      <div className="header_wrapper">
        <div className="logo">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              // width={180}
              height={80}
            />
          </a>
        </div>
        <div className={open ? "menu_mobile open" : "menu_mobile"}>
          <div className="menu_mobile_relative">
            <div className="menu_mobile_overlay"></div>
            <ul className="menu_mobile_items">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a
                  href={isHomePage ? "#about" : "/"}
                  onClick={() => {
                    handleScroll(aboutRef.current);
                  }}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href={isHomePage ? "#price" : "/"}
                  onClick={() => {
                    handleScroll(priceRef.current);
                  }}
                >
                  Pricing
                </a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={"menu"}>
          <a href="/">Home</a>
          <a
            href={isHomePage ? "#about" : "/"}
            onClick={() => {
              handleScroll(aboutRef.current);
            }}
          >
            About
          </a>
          <a
            href={isHomePage ? "#price" : "/"}
            onClick={() => {
              handleScroll(priceRef.current);
            }}
          >
            Pricing
          </a>
          <a href="/contact">Contact</a>
          <div className="number">
            <img
              src="https://i8.amplience.net/i/jpl/phone-call-877402671b025927871de04103cbe388"
              alt=""
            />
            <div className="phone_number" href="tel:01619268999">
              01619268999
            </div>
          </div>
        </div>
        <button
          className={open === false ? "hamburger" : "hamburger is-active"}
          onClick={() => setOpen(!open)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  );
}

export default NavBar;
