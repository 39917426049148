import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Aos from "aos";
import "aos/dist/aos.css";

function Contact() {
  const [name, setName] = useState(null);
  const [number, setNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [service, setService] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const server = "https://sarah-server.vercel.app";

  useEffect(() => {
    Aos.init();
  }, []);

  function handleChange(event) {
    console.log(event.target.value);
    if (event.target.id === "name") {
      setName(event.target.value);
    } else if (event.target.id === "number") {
      setNumber(event.target.value);
    } else if (event.target.id === "email") {
      setEmail(event.target.value);
    } else if (event.target.id === "service") {
      setService(event.target.value);
    } else if (event.target.id === "message") {
      setMessage(event.target.value);
    }
  }

  function formSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .put("https://sarah-server-izddkcaa9-johnski9000.vercel.app", {
        name: name,
        email: email,
        number: number,
        service: service,
        message: message,
      })
      .then(function (response, error) {
        if (response.status === 200) {
          console.log("email sent");
          setLoaded(true);
        }
        if (error) {
          console.log(error);
          setLoading(false);
          setError(true);
        }
      });
  }
  const loading_animation = loading === true && loaded === false;
  const loading_complete = loading === true && loaded === true;
  const loading_error = loading === true && error === true;

  return (
    <div>
      <NavBar />
      <div className="contact_top_img">
        {/* <h2>Contact</h2> */}
        <div></div>
      </div>

      <div className="contact_container" data-aos="fade-up">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2371.635424620071!2d-2.355212184227718!3d53.386257979978565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bae0e82b8f52f%3A0xf5a24fdb4b57e0cf!2s22%20The%20Downs%2C%20Altrincham%20WA14%202PU%2C%20UK!5e0!3m2!1sen!2sus!4v1601138221085!5m2!1sen!2sus"
          // width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0, flex: 1, width: "100%" }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="map"
        />

        {loading === false && (
          <div className="contact_form">
            <h2>Get in touch</h2>
            <div className="opening_hours">
              <h3>Opening Hours</h3>
              <div>Monday - Friday : 10AM - 6:30PM</div>
              <div>Saturday : 10AM - 6PM Saturday: 11AM - 4PM</div>
            </div>
            <form>
              <div>
                <input
                  type="name"
                  id="name"
                  placeholder="Name"
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div>
                <input
                  type="number"
                  id="number"
                  placeholder="Contact Number"
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div>
                <select onChange={(event) => handleChange(event)} id="service">
                  <option value="/">Select Service</option>
                  <option value="gents-wash-and-cut">
                    Gents - Wash and Cut (From £25)
                  </option>
                  <option value="ladies-cut-and-blowdry">
                    Ladies - Cut and Blowdry (From £45)
                  </option>
                  <option value="ladies-blow-dry">
                    Ladies - Blow Dry (From £25)
                  </option>
                  <option value="full-head-colour">
                    Full Head Colour (From £50)
                  </option>
                  <option value="colour-and-highlights">
                    Colour and Highlights (From £60)
                  </option>
                  <option value="full-head-highlights">
                    Full Head Highlights (From £70)
                  </option>
                  <option value="half-head-highlights">
                    Half Head Highlights (From £60)
                  </option>
                  <option value="halo-highlights">
                    Halo Highlights (From £50)
                  </option>
                  <option value="toner">Toner (From £15)</option>
                  <option value="keratin-blow-dry">
                    Keratin Blow Dry (From £130)
                  </option>
                </select>
              </div>
              <div>
                <textarea
                  placeholder="Message"
                  onChange={(event) => handleChange(event)}
                  id="message"
                />
              </div>
              <button onClick={(event) => formSubmit(event)}>
                Send Request
              </button>
            </form>
          </div>
        )}
        {loading_animation && (
          <div className="contact_loading">
            <img
              src="https://jpl.a.bigcontent.io/v1/static/spinner-1s-200px-9064981b80e59f1b663c17fab41563c9"
              alt="ads"
            />
          </div>
        )}
        {loading_complete === true && (
          <div className="contact_loaded">We'll be in touch!</div>
        )}
        {loading_error && (
          <div className="contact_loaded">There was an error!</div>
        )}
      </div>

      <Footer />
      <div
        className="copyright"
        style={{
          backgroundColor: "#61696c",
          textAlign: "center",
          color: "#ffffff",
        }}
      >
        Copyright © 2024 Colin James Hairdressing ltd
      </div>
      <div
        className="copyright"
        style={{
          backgroundColor: "#61696c",
          textAlign: "center",
          color: "#ffffff",
        }}
      >
        Powered by{" "}
        <a
          href="https://jw-digital.co.uk/"
          style={{ color: "white", fontWeight: "bold" }}
        >
          JW Digital
        </a>
      </div>
    </div>
  );
}

export default Contact;
