import React, { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";
import Aos from "aos";
import "aos/dist/aos.css";

function Banner() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="banner_wrapper">
      <div class="background-overlay"></div>
      <div class="custom-shape-divider-top-1678372368">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      {/* <Parallax translateY={[60, -40]}> */}
      <div className="text_overlay_wrapper">
        <div className="text_overlay" data-aos="fade-right">
          <h4 className="text_overlay_address">
            {/* 123, Central Square, Boston */}
          </h4>
          <h1 className="text_overlay_title">Welcome to our Hair Salon</h1>
          <div className="text_overlay_subtitle">
            A place to pamper yourself with the finest hair care and relaxation
          </div>
          <div className="text_overlay_button_container">
            <a href="/contact">Book Appointment</a>
            <a href="tel:01619268999">Contact Now</a>
          </div>
        </div>
        {/* <div></div> */}
      </div>
      {/* </Parallax> */}
    </div>
  );
}

export default Banner;
